<template>
  <div class="category">
    <router-view />
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.category {
  padding: 0 2.6042vw;
  flex-grow: 1;
}
</style>
